import React from "react";
import TrackVisibility from "react-on-screen";

import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "test",
  avatarImage: "/images/avatar-modified (2).png",
  content:
    "I am test, web developer from London, United Kingdom. I have rich experience in web site design and building and customization, also I am good at WordPress.",
};

const progressData = [
  {
    id: 1,
    title: "Development",
    percantage: 85,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "UI/UX Design",
    percantage: 95,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Photography",
    percantage: 70,
    progressColor: "#6C6CE5",
  },
];

const brandlogoData = [
  {
    id: 1,
    skill: "html",
    iconClassName: "fab fa-html5",
    image: "images/client-1.svg",
  },
  {
    id: 2,
    skill: "css",
    iconClassName: "fab fa-css3-alt",
    image: "images/client-2.svg",
  },
  {
    id: 3,
    skill: "python",
    iconClassName: "fab fa-python",
    image: "images/client-3.svg",
  },
  {
    id: 4,
    skill: "php",
    iconClassName: "fab fa-php",
    image: "images/client-4.svg",
  },
  {
    id: 5,
    skill: "database",
    iconClassName: "fas fa-database",
    image: "images/client-5.svg",
  },
  {
    id: 6,
    skill: "react",
    iconClassName: "fab fa-react",
    image: "images/client-6.svg",
  },
  {
    id: 7,
    skill: "terminal",
    iconClassName: "fas fa-terminal",
    image: "images/client-7.svg",
  },
  {
    id: 8,
    skill: "nodejs",
    iconClassName: "fab fa-node-js",
    image: "images/client-8.svg",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div className="mt-3">
                    <a href="!#" className="btn btn-default">
                      Download CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper">
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="branglogos">
        <div className="container">
          <div className="row">
            {brandlogoData.map((brandlogo) => (
              <div className="col-md-3 col-6" key={brandlogo.id}>
                <div className="client-item">
                  <div className="inner">
                    {/* <img src={brandlogo.image} alt="client-name" /> */}
                    <i
                      className={`${brandlogo.iconClassName} ${brandlogo.skill} fa-3x`}></i>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
