import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import ScrollToTop from "../components/elements/ScrollToTop";
import Footer from "../components/layouts/Footer";
import HeaderOther from "../components/layouts/HeaderOther";
// import Alert from "@material-ui/lab/Alert";

function Donate({ history }) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [bitcoin1, setBitcoin1] = useState(
    "13Jm2TtZ7qxg2VYf9WuVMZZHDFwwMJcqBG"
  );
  const [bitcoin2, setBitcoin2] = useState(
    "0xaBee8971681c46f97DA61c0045feeef9d9600e7A"
  );
  const [bitcoin3, setBitcoin3] = useState(
    "LbiuXz2P7bj1bNfhTx5reoGM6UXozXuWcV"
  );
  const [themeName, setThemeName] = useState("dark");

  themeName === "dark"
    ? document.body.classList.remove("dark")
    : document.body.classList.add("dark");
  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });
  const handleToggleDarkmode = (e) =>
    !e.target.checked ? setThemeName("dark") : setThemeName("light");
  return (
    <div>
      <HeaderOther
        logoSource="/images/martijnsvgdark.svg"
        logoSourceDark="/images/martijnsvglight.svg"
        toggleMenu={toggleMenu}
        handleToggleDarkmode={handleToggleDarkmode}
        headerToggler={headerToggler}
        theme={themeName}
      />

      <main className={"content"}>
        <ScrollToTop />

        <div className="container" style={{ marginTop: "8rem" }}>
          <div className="well well-lg">
            <h1>Donate</h1>
          </div>
          <h2>Support</h2>
          <p>
            If you like what I am doing on the Internet - my articles, websites,
            tutorials, projects and whole other stuff - you can support my work.
            This will help me realize my ideas and contribute to the fact that I
            will be able to spend more time on the site development and
            projects.
          </p>

          <p>
            {" "}
            All financial donations are given first of all to server maintenance
            and then to coffee. You can support me in several ways.
          </p>
          <div style={{ marginBottom: "1rem" }}>
            <a
              href="https://magicmirror.builders/paypal"
              src="_blank"
              className="btn btn-primary btn-md">
              <i className="fa fa-fw fa-paypal" aria-hidden="true"></i> Donate
              using PayPal
            </a>
          </div>
          <h2>Cryptocurrency Donation</h2>
          <p>One of the donation options is the transfer of cryptocurrency.</p>
          <div className="row">
            <div className="col-md-7">
              <div>
                <a className="crypto-list-item">
                  <div className="crypto-logo">
                    <img src="/images/bitcoin2.svg" />
                  </div>
                  <div className="crypto-currency">BTC</div>
                  <div
                    className="crypto-address"
                    onClick={() => {
                      navigator.clipboard.writeText(bitcoin1);
                      setBitcoin1("Copied to clipboard!");
                      setTimeout(() => setBitcoin1(bitcoin1), 2000);
                    }}>
                    {bitcoin1}
                  </div>
                </a>
                <a className="crypto-list-item">
                  <div className="crypto-logo">
                    <img src="/images/bitcoin1.svg" />
                  </div>
                  <div className="crypto-currency">ETH</div>
                  <div
                    className="crypto-address"
                    onClick={() => {
                      navigator.clipboard.writeText(bitcoin2);
                      setBitcoin2("Copied to clipboard!");
                      setTimeout(() => setBitcoin2(bitcoin2), 2000);
                    }}>
                    {bitcoin2}
                  </div>
                </a>
                <a className="crypto-list-item">
                  <div className="crypto-logo">
                    <img src="/images/bitcoin3.svg" />
                  </div>
                  <div className="crypto-currency">LTC</div>
                  <div
                    className="crypto-address"
                    onClick={() => {
                      navigator.clipboard.writeText(bitcoin3);
                      setBitcoin3("Copied to clipboard!");
                      setTimeout(() => setBitcoin3(bitcoin3), 2000);
                    }}>
                    {bitcoin3}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <h2>One time donation</h2>
          <p>
            The friendly and freeway for anyone to support my work for a price
            of coffee.
          </p>
          <a href="https://ko-fi.com/martijnlindeman" target="_blank">
            <img
              src="https://cdn.buymeacoffee.com/buttons/default-orange.png"
              alt="Buy Me A Coffee"
              height="41"
              width="174"
            />
          </a>
          <h2>Thank you</h2>
          <p>
            Thank you for your support and good words. Praise, suggestion or
            opinion about what I’m doing is one of the biggest donations I can
            get. Everything I do, I try to do it with passion and for others. I
            share my thoughts and knowledge because I like to do it. When the
            recipients react with a good word, a nice comment or a grant in the
            form of cryptocurrency, I really know that everything is alright and
            someone appreciates it.
          </p>
          <p>
            <i className="fa fa-calendar" aria-hidden="true"></i> Updated on 03
            December 2021
          </p>
        </div>

        <Element name="section-contact">
          <Footer theme={themeName} />
        </Element>
      </main>
    </div>
  );
}

export default Donate;
