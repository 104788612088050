import React from "react";
import Typed from "react-typed"; /* is for text strings I'm a */
import { Link } from "react-scroll"; /* is for scrolling to element*/
import { Parallax, Background } from "react-parallax";

function Herosection(props) {
  const { x, y } = props.position;
  const { height, width } = props.elementDimensions;

  return (
    <section
      id="home"
      className={
        props.theme === "light"
          ? "home d-flex align-items-center light"
          : "home d-flex align-items-center"
      }>
      <div className="container">
        <div className="intro">
          <img
            src="images/favicon.svg" /* picture */
            alt="Martijn Lindeman"
            className="mb-4"
          />

          <h1 className="mb-2 mt-0">Martijn Lindeman</h1>
          <p>
            I'm a{" "}
            <Typed
              strings={[
                "Digital Marketing Specialist",
                "Security Researcher",
                "Programmer",
                "Technology Geek",
                "Photography Lover",
                "Digital Marketing Specialist",
              ]}
              typeSpeed={80}
              backSpeed={40}
              attr="value"
              loop>
              <label value></label>
            </Typed>
          </p>

          <ul className="social-icons light list-inline mb-0 mt-4">
            <li className="list-inline-item">
              <a href="https://github.com/MartijnLindeman">
                <i className="fab fa-github git"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://twitter.com/LindemanMartijn">
                <i className="fab fa-twitter tweet"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.linkedin.com/in/martijn-lindeman1/">
                <i className="fab fa-linkedin-in link"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://app.hackthebox.com/profile/165049">
                <i className="fas fa-cube cube"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="https://www.instagram.com/martijn.lindeman/">
                <i className="fab fa-instagram insta"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="!#">
                <i className="fas fa-cloud-download-alt cloud"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="!#">
                <i className="fas fa-rss rss"></i>
              </a>
            </li>
          </ul>

          <div className="mt-4">
            <Link
              className="btn btn-default"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}>
              Hire me
            </Link>
          </div>
        </div>

        <div className="scroll-down">
          <Link
            to="section-about"
            spy={true}
            smooth={true}
            duration={500}
            className="mouse-wrapper">
            <span className="mouse-icon">
              <span className="wheel">
                <span className="scroll"></span>
              </span>
            </span>
          </Link>
        </div>

        {/* <div data-jarallax data-speed="0.2" class="jarallax">
          <img class="jarallax" src="images/bg.jpg"  alt=""/>
        </div> */}
        <div data-jarallax data-speed="0.2" className={"jarallax"}>
          <Parallax
            bgImage="images/bg.jpg"
            style={{
              height: "100%",
              width: "100%",
            }}
            renderLayer={(percentage) => (
              <div
                style={{
                  position: "absolute",
                  background: `rgba(255, 125, 0, ${percentage * 1})`,
                  left: "100%",
                  top: "100%",
                  width: percentage * 200,
                  height: percentage * 200,
                }}
              />
            )}></Parallax>
        </div>
      </div>
    </section>
  );
}

export default Herosection;
