import React, { useEffect, useState } from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import ScrollToTop from "../components/elements/ScrollToTop";
import Footer from "../components/layouts/Footer";
import Header3 from "../components/layouts/Header3";
import About from "../components/sections/About";
import AboutDark from "../components/sections/AboutDark";
import Blogs from "../components/sections/Blogs";
import BrandlogosDark from "../components/sections/BrandlogosDark";
import Contact from "../components/sections/Contact";
import Experiences from "../components/sections/Experiences";
import Herosection from "../components/sections/Herosection";
import Pricing from "../components/sections/Pricing";
import Services from "../components/sections/Services";
import Testimonials from "../components/sections/Testimonials";
import Works from "../components/sections/Works";
import Brandlogos from "../components/sections/Brandlogos";

function Homepage() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [themeName, setThemeName] = useState("dark");
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    let progressBarHandler = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scroll = `${totalScroll / windowHeight}`;

      setScroll(scroll);
    };

    window.addEventListener("scroll", progressBarHandler);

    return () => window.removeEventListener("scroll", progressBarHandler);
  });
  console.log({ scroll });
  themeName === "dark"
    ? document.body.classList.remove("dark")
    : document.body.classList.add("dark");
  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });
  const handleToggleDarkmode = (e) =>
    !e.target.checked ? setThemeName("dark") : setThemeName("light");
  return (
    <div>
      <div id="progressBarContainer">
        <div
          id="progressBar"
          style={{ transform: `scale(${scroll}, 1)`, opacity: `${scroll}` }}
        />
      </div>
      <Header3
        logoSource="/images/martijnsvgdark.svg"
        logoSourceDark="/images/martijnsvglight.svg"
        toggleMenu={toggleMenu}
        handleToggleDarkmode={handleToggleDarkmode}
        headerToggler={headerToggler}
        theme={themeName}
      />
      <main className={"content"}>
        <ScrollToTop />
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection theme={themeName} />
          </ReactCursorPosition>
        </Element>
        <Element name="section-about">
          {themeName === "dark" ? <About /> : <AboutDark />}
        </Element>
        <Element name="section-services">
          <Services />
        </Element>
        <Element name="section-experiences">
          <Experiences />
        </Element>
        <Element name="section-works">
          <Works />
        </Element>
        <Element name="section-blogs">
          <Blogs />
        </Element>
        <Element name="section-contact">
          <Contact />
        </Element>
        <Element name="section-footer">
          <Footer theme={themeName} />
        </Element>
      </main>
    </div>
  );
}

export default Homepage;
