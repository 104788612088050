import React, { useState, useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";
import Portfolio from "../elements/Portfolio";

const filters = [
  {
    id: 1,
    text: "All",
  },
  {
    id: 2,
    text: "Offensive Security",
  },
  {
    id: 3,
    text: "Comptia",
  },
  {
    id: 4,
    text: "eLearnSecurity",
  },
  {
    id: 5,
    text: "Microsoft",
  },
  {
    id: 6,
    text: "Google",
  },
  {
    id: 7,
    text: "Red Hat",
  },
];

const allData = [
  {
    id: 1,
    title: "OSCP",
    category: "offensive security" /* input small letters only */,
    image: "images/works/OSCP-badge.png",
    popupLink: ["images/works/OSCP-badge.png"],
    link: "https://www.credly.com/badges/0ed0ae2e-e2a0-4903-a63c-e3281831f460",
  },
  {
    id: 2,
    title: "OSWP",
    category: "offensive security" /* input small letters only */,
    image: "images/works/WiFu-OSWP-badge.png",
    popupLink: ["images/works/WiFu-OSWP-badge.png"],
    link: "https://www.credly.com/badges/7e15851d-74d2-4547-ae6f-943faa709ec7",
  },
  {
    id: 3,
    title: "ecptx v2",
    category: "elearnsecurity",
    image: "images/works/ecptx_v2_certificate.jpg",
    popupLink: ["images/works/ecptx_v2_certificate.jpg"],
    link: "https://www.credly.com/badges/7e15851d-74d2-4547-ae6f-943faa709ec7",
  },

  {
    id: 4,
    title: "Microsoft AZ-900",
    category: "microsoft" /* input small letters only */,
    image: "images/works/azure-fundamentals-600x600.png",
    popupLink: ["images/works/Microsoft-Azure-Fundamentals.jpg"],
    link: "https://www.credly.com/badges/0ed0ae2e-e2a0-4903-a63c-e3281831f460",
  },
  {
    id: 5,
    title: "Microsoft MS-900",
    category: "microsoft",
    image: "images/works/microsoft365-fundamentals-600x600.png",
    popupLink: ["images/works/Microsoft-365-Fundamentals.jpg"],
    link: "https://www.credly.com/badges/0ed0ae2e-e2a0-4903-a63c-e3281831f460",
  },
  {
    id: 6,
    title: "Microsoft SC-900",
    category: "microsoft",
    image:
      "images/works/security-compliance-and-identity-fundamentals-600x600.png",
    popupLink: [
      "images/works/Microsoft-security-compliance-and-identity-fundamentals.jpg",
    ],
    link: "https://www.credly.com/badges/0ed0ae2e-e2a0-4903-a63c-e3281831f460",
  },
  {
    id: 7,
    title: "Microsoft AZ-500",
    category: "microsoft",
    image: "images/works/azure-security-engineer-associate600x600.png",
    popupLink: ["images/works/Microsoft-Azure-Security-Engineer-Associate.jpg"],
    link: "https://www.credly.com/badges/0ed0ae2e-e2a0-4903-a63c-e3281831f460",
  },
  {
    id: 8,
    title: "Google Ads Search",
    category: "google",
    image: "images/works/Google ads-certification.jpg",
    popupLink: ["images/works/Google ads-certification.jpg"],
    link: "https://www.credly.com/badges/0ed0ae2e-e2a0-4903-a63c-e3281831f460",
  },
  {
    id: 9,
    title: "Google Shopping Ads",
    category: "google",
    image: "images/works/Google-Shopping-ads-Certification.jpg",
    popupLink: ["images/works/Google-Shopping-ads-Certification.jpg"],
    link: "https://www.credly.com/badges/0ed0ae2e-e2a0-4903-a63c-e3281831f460",
  },
  {
    id: 10,
    title: "Google Analytics",
    category: "google",
    image: "images/works/Google-Analytics-Individual-Qualification.jpg",
    popupLink: ["images/works/Google-Analytics-Individual-Qualification.jpg"],
    link: "https://www.credly.com/badges/0ed0ae2e-e2a0-4903-a63c-e3281831f460",
  },
  {
    id: 11,
    title: "Google Analytics",
    category: "google",
    image: "images/works/Google-Analytics-Poweruser-Certification.jpg",
    popupLink: ["images/works/Google-Analytics-Poweruser-Certification.jpg"],
    link: "https://www.credly.com/badges/0ed0ae2e-e2a0-4903-a63c-e3281831f460",
  },
];

function Works() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(10);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    setActiveFilter(e.target.textContent.toLowerCase());
    let tempData;
    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category === e.target.textContent.toLowerCase() &&
          data.id <= dataVisibleCount
      );
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        console.log("they are same");
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.category === activeFilter && data.id <= tempCount
          )
        );
      }
    }
  };

  return (
    <section id="works">
      <div className="container">
        <Pagetitle title="Certifications" />
        {/* Start Portfolio Filters */}
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}>
          <ul className="portfolio-filter list-inline">
            {filters.map((filter) => (
              <li className="list-inline-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter
                      ? "text-capitalize current"
                      : "text-capitalize"
                  }>
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </ScrollAnimation>
        {/* End Portfolio Filters */}

        {/* Start Portfolio Items */}
        <div className="row portfolio-wrapper">
          {visibleItems.map((item) => (
            <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
              <Portfolio portfolio={item} />
            </div>
          ))}
        </div>
        {/* End Portfolio Items */}

        <div className="load-more text-center mt-4">
          <button
            className="btn btn-default"
            onClick={handleLoadmore}
            disabled={noMorePost ? "disabled" : null}>
            {noMorePost ? (
              "No more items"
            ) : (
              <span>
                <i className="fas fa-spinner"></i> Load more
              </span>
            )}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Works;
