import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Schedule from "@material-ui/icons/Schedule";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const experienceData = [
  {
    id: 1,
    title: "Web Designer",
    years: "2019 - Present",
    place: "Dublin IR",
    content:
      "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  },
  {
    id: 2,
    title: "Front-End Developer",
    years: "2017 - 2013",
    place: "Dublin IR",
    content:
      "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  },
  {
    id: 3,
    title: "Back-End Developer",
    years: "2013 - 2011",
    place: "Dublin IR",
    content:
      "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  },
  {
    id: 4,
    title: "Back-End Developer",
    years: "2011 - 2009",
    place: "Dublin IR",
    content:
      "Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.",
  },
  {
    id: 5,
    title: "Education",
    place: "The Netherlands",
    years: "2008 - 2014",
  },
];

function Experiences({ theme }) {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <VerticalTimeline>
          {experienceData.map((el) => (
            <VerticalTimelineElement
              key={el.years}
              className="vertical-timeline-element--work"
              contentStyle={{
                background: theme === "light" ? "#696969" : "#dfdfe4",
                color: theme === "light" ? "#fff" : "#454360",
              }}
              contentArrowStyle={{
                borderRight:
                  theme === "light"
                    ? "7px solid  #696969"
                    : "7px solid  #dfdfe4",
              }}
              date={el.years}
              iconStyle={{
                background: theme === "light" ? "#696969" : "#dfdfe4",
                color: theme === "light" ? "#fff" : "#454360",
                // boxShadow: theme === "light" ? " #fff" : " #454360",
              }}
              icon={<Schedule />}>
              <h3 className="vertical-timeline-element-title">{el.title}</h3>
              <h4 className="vertical-timeline-element-subtitle">{el.place}</h4>
              <p>{el.content}</p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </section>
  );
}

export default Experiences;
