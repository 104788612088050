import React, { useState } from "react";
import { Link, scroller } from "react-scroll";
import Logo from "../elements/Logo";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from "react-router-dom";

function HeaderOther({
  light,
  logoSource,
  logoSourceDark,
  bootstrapNav,
  theme,
  handleToggleDarkmode,
}) {
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const history = useHistory();
  const scrollTarget = (target) =>
    scroller.scrollTo(target, { smooth: true, duration: 700 });

  const scrollToPage = async (target) => {
    if (history.location.pathname !== "/") {
      await history.push("/");
    }
    scrollTarget(target);
  };

  const handleMobilenav = (e) => {
    e.preventDefault();
    setToggleNavbar(!toggleNavbar);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content-3")) {
      setToggleNavbar(false);
    }
  });

  return (
    <header
      className={
        theme === "dark"
          ? "desktop-header-3 fixed-top light"
          : "desktop-header-3 fixed-top"
      }>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <Logo
            logoSource={theme === "dark" ? logoSourceDark : logoSource}
            bootstrapNav={bootstrapNav}
          />
          <button
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbarNavDropdown"
            data-toggle="collapse"
            type="button"
            onClick={handleMobilenav}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={
              toggleNavbar
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }
            id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto scrollspy">
              <li className="nav-item">
                <div
                  className="nav-link"
                  activeClass="active"
                  onClick={() => scrollToPage("section-home")}>
                  Home
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link"
                  activeClass="active"
                  onClick={() => scrollToPage("section-about")}>
                  About
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link"
                  activeClass="active"
                  onClick={() => scrollToPage("section-services")}>
                  Service
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link"
                  activeClass="active"
                  onClick={() => scrollToPage("section-experiences")}>
                  Experience
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link"
                  activeClass="active"
                  onClick={() => scrollToPage("section-works")}>
                  Works
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link"
                  activeClass="active"
                  onClick={() => scrollToPage("section-blogs")}>
                  Blog
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="nav-link"
                  activeClass="active"
                  onClick={() => scrollToPage("section-contact")}>
                  Contact
                </div>
              </li>
              <li className="nav-item">
                <FormControlLabel
                  value="DarkMode"
                  className={"cust-switch"}
                  control={
                    <Switch onClick={handleToggleDarkmode} color="default" />
                  }
                  label={theme === "light" ? "Light" : "Dark "}
                />
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default HeaderOther;
