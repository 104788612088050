import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import ScrollToTop from "../components/elements/ScrollToTop";
import Footer from "../components/layouts/Footer";
import Header3 from "../components/layouts/Header3";
import HeaderOther from "../components/layouts/HeaderOther";

function PrivacyPolicy() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [themeName, setThemeName] = useState("dark");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  themeName === "dark"
    ? document.body.classList.remove("dark")
    : document.body.classList.add("dark");
  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });
  const handleToggleDarkmode = (e) =>
    !e.target.checked ? setThemeName("dark") : setThemeName("light");
  return (
    <div>
      <HeaderOther
        logoSource="/images/martijnsvgdark.svg"
        logoSourceDark="/images/martijnsvglight.svg"
        toggleMenu={toggleMenu}
        handleToggleDarkmode={handleToggleDarkmode}
        headerToggler={headerToggler}
        theme={themeName}
      />
      <main className={"content"}>
        <ScrollToTop />
        <div className="container" style={{ marginTop: "8rem" }}>
          <div className="well well-lg">
            <h1>Privacy Policy</h1>
          </div>

          <h2>When do we collect information?</h2>
          <p>Fill out a form or enter information on our site.</p>
          <h2>How do we use your information?</h2>
          <p>
            We may use the information we collect from you when you surf the
            website, or use certain other site features in the following ways:
            To improve our website in order to better serve you.
          </p>
          <h2>When do we collect information?</h2>
          <p>Fill out a form or enter information on our site.</p>
          <h2>Do we use cookies?</h2>
          <p>
            Yes. Cookies are small files that a site or its service provider
            transfers to your computer’s hard drive through your Web browser (if
            you allow) that enables the site’s or service provider’s systems to
            recognize your browser and capture and remember certain information.
            For instance, we use cookies to help us remember and process the
            items in your shopping cart. They are also used to help us
            understand your preferences based on previous or current site
            activity, which enables us to provide you with improved services. We
            also use cookies to help us compile aggregate data about site
            traffic and site interaction so that we can offer better site
            experiences and tools in the future.
          </p>
          <h2>Third party links</h2>
          <p>
            Occasionally, at our discretion, we may include or offer third party
            products or services on our website. These third party sites have
            separate and independent privacy policies. We, therefore, have no
            responsibility or liability for the content and activities of these
            linked sites. Nonetheless, we seek to protect the integrity of our
            site and welcome any feedback about these sites.
          </p>
          <h2>Consent</h2>
          <p>By using this Site, you consent to our Privacy Policy.</p>
          <h2>Changes to this Privacy Policy</h2>
          <p>
            If there are any changes to this privacy policy, we will replace
            this page with an updated version. It is therefore in your own
            interest to check the “Privacy Policy” page any time you access our
            website so as to be aware of any changes which may occur from time
            to time.
          </p>
          <h2>Azure Functions</h2>
          <p>
            This website uses Azure Functions as FaaS services. Azure Functions
            may collect additional information. Please refer to Microsoft
            Privacy Statement for more information.
          </p>
          <h2>Google Analytics</h2>
          <p>
            This website uses Google Analytics to collect information to help
            improve this website. Please refer to Google’s Privacy Policy for
            more information
          </p>
          <h2>Cloudflare</h2>
          <p>
            This website uses Cloudflare as CDN for delivery and optimization,
            and Cloudflare may collect additional information. Please refer to
            Cloudflare’s Privacy Policy for more information.
          </p>
          <h2>GitHub</h2>
          <p>
            GitHub may collect additional information. Please refer to GitHub
            Privacy Statement for more information.
          </p>
          <p>
            <i className="fa fa-calendar" aria-hidden="true"></i> Updated on 21
            August 2021
          </p>
        </div>
        <Element name="section-contact">
          <Footer theme={themeName} />
        </Element>
      </main>
    </div>
  );
}

export default PrivacyPolicy;
