import React from "react";
import AutoComplete from "./Autocomplete";

const allBlogs = [
  {
    id: 1,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Martijn Lindeman",
    category: "Reviews",
  },
  {
    id: 2,
    title: "Common Misconceptions About Payment",
    image: "images/blog/2.svg",
    filesource: "../../blogs/common-misconceptions-about-payment.md",
    date: "07 February, 2020",
    author: "Martijn Lindeman",
    category: "Tutorial",
  },
  {
    id: 3,
    title: "3 Things To Know About Startup Business",
    image: "images/blog/3.svg",
    filesource: "../../blogs/things-to-know-about-startup-business.md",
    date: "06 February, 2020",
    author: "Martijn Lindeman",
    category: "Business",
  },
  {
    id: 4,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Martijn Lindeman",
    category: "Reviews",
  },
  {
    id: 5,
    title: "Common Misconceptions About Payment",
    image: "images/blog/2.svg",
    filesource: "../../blogs/common-misconceptions-about-payment.md",
    date: "07 February, 2020",
    author: "Martijn Lindeman",
    category: "Tutorial",
  },
  {
    id: 6,
    title: "3 Things To Know About Startup Business",
    image: "images/blog/3.svg",
    filesource: "../../blogs/things-to-know-about-startup-business.md",
    date: "06 February, 2020",
    author: "Martijn Lindeman",
    category: "Business",
  },
  {
    id: 7,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Martijn Lindeman",
    category: "Reviews",
  },
  {
    id: 8,
    title: "Common Misconceptions About Payment",
    image: "images/blog/2.svg",
    filesource: "../../blogs/common-misconceptions-about-payment.md",
    date: "07 February, 2020",
    author: "Martijn Lindeman",
    category: "Tutorial",
  },
  {
    id: 9,
    title: "3 Things To Know About Startup Business",
    image: "images/blog/3.svg",
    filesource: "../../blogs/things-to-know-about-startup-business.md",
    date: "06 February, 2020",
    author: "Martijn Lindeman",
    category: "Business",
  },
  {
    id: 10,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Martijn Lindeman",
    category: "Reviews",
  },
];
function BlogListSearch() {
  return (
    <>
      <AutoComplete suggestions={allBlogs} />
    </>
  );
}

export default BlogListSearch;
