import React, { useEffect, useState } from "react";
import XMLViewer from "react-xml-viewer";
import XMLText from "../sitemap.xml";
import axios from "axios";

function Sitemap() {
  const [state, setState] = useState();
  useEffect(() => {
    axios
      .get(XMLText, {
        "Content-Type": "application/xml; charset=utf-8",
      })
      .then((response) => {
        setState(response.data);
        // console.log("Your xml file as string", response.data);
      });
    return () => {};
  }, []);
  console.log({ state });

  return (
    <div>
      {/* <XMLViewer xml={state} /> */}
      {state}
    </div>
  );
}

export default Sitemap;
