import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import Fancybox from "./Fancybox";

function Portfolio({ portfolio }) {
  const { category, title, image, popupLink, link } = portfolio;
  const [toggler, setToggler] = useState(false);
  const handleLightbox = (e) => {
    if (link) {
      e.preventDefault();
      setToggler(!toggler);
    }
  };

  const handleOpenLink = (link) => {
    window.open(link);
  };

  const handleIcon = () => {
    if (link) {
      return <i className="icon-link"></i>;
    } else if (popupLink) {
      if (popupLink.length > 1) {
        if (popupLink.toString().match(/youtube/g)) {
          return <i className="icon-camrecorder"></i>;
        }
        return <i className="icon-picture"></i>;
      } else if (popupLink.toString().match(/youtube/g)) {
        return <i className="icon-camrecorder"></i>;
      } else {
        return <i className="icon-magnifier-add"></i>;
      }
    }
    return <i className="icon-magnifier-add"></i>;
  };
  return (
    <>
      <div className="work-image">
        <div className="portfolio-item rounded shadow-dark">
          <div className="details">
            <span className="term text-capitalize">{category}</span>
            <h4 className="title">{title}</h4>
            <div className="btn-container">
              {/* <a className="link" onClick={handleLightbox}>
                <span className="more-button">
                  <i className="icon-magnifier-add" />
                </span>
              </a> */}
              <Fancybox options={{ infinite: false }}>
                <a
                  data-fancybox="gallery"
                  data-src={popupLink}
                  className="button button--secondary">
                  <span className="more-button">
                    <i className="icon-magnifier-add" />
                  </span>
                </a>
              </Fancybox>
              <a className="link" onClick={() => handleOpenLink(link)}>
                <span className="more-button2">
                  <i className="icon-link" />
                </span>
              </a>
            </div>
          </div>
          <div className="thumb">
            <img src={image} alt="Portfolio-title" />
            <div className="mask"></div>
          </div>
        </div>
      </div>
      {/* {popupLink && <FsLightbox toggler={toggler} sources={popupLink} />} */}
    </>
  );
}

export default Portfolio;
