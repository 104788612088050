import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const AutoComplete = ({ suggestions }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");
  const ref = useRef();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const onChange = (e) => {
    const userInput = e.target.value;
    setIsMenuOpen(true);

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.title.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };
  const onClick = (e) => {
    setFilteredSuggestions([]);
    setInput(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };
  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      isMenuOpen && (
        <div className="suggestions-container" ref={ref}>
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;
              // Flag the active suggestion with a class
              // if (index === activeSuggestionIndex) {
              //   className = "suggestion-active";
              // }
              return (
                <Link to="#">
                  <li
                    className={className}
                    key={suggestion.id}
                    onClick={onClick}>
                    {suggestion.title}
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
      )
    ) : (
      <div class="suggestions-container no-suggestions">
        <em>No posts found!</em>
      </div>
    );
  };
  return (
    <>
      <input
        type="text"
        onChange={onChange}
        placeholder="Search ..."
        // onKeyDown={onKeyDown}
        value={input}
      />
      {showSuggestions && input && <SuggestionsListComponent />}
    </>
  );
};
export default AutoComplete;
