import React from "react";
import { Link } from "react-router-dom";

function Footer({ light, theme }) {
  return (
    <footer className={theme === "dark" ? "footer light" : "footer"}>
      <div className="container">
        <span className="copyright">
          <div className="icp">
            <p>
              <div className="social-icons light">
                <div className="list-inline-item">
                  <a href="https://github.com/MartijnLindeman">
                    <i className="fab fa-github git fa-lg"></i>
                  </a>
                </div>
                <div className="list-inline-item">
                  <a href="https://twitter.com/LindemanMartijn">
                    <i className="fab fa-twitter tweet fa-lg"></i>
                  </a>
                </div>
                <div className="list-inline-item">
                  <a href="https://www.linkedin.com/in/martijn-lindeman1/">
                    <i className="fab fa-linkedin-in link fa-lg"></i>
                  </a>
                </div>
                <div className="list-inline-item">
                  <a href="https://app.hackthebox.com/profile/165049">
                    <i className="fas fa-cube cube fa-lg"></i>
                  </a>
                </div>
                <div className="list-inline-item">
                  <a href="https://www.instagram.com/martijn.lindeman/">
                    <i className="fab fa-instagram insta fa-lg"></i>
                  </a>
                </div>
                <div className="list-inline-item">
                  <a href="!#">
                    <i className="fas fa-cloud-download-alt cloud fa-lg"></i>
                  </a>
                </div>
                <div className="list-inline-item">
                  <a href="!#">
                    <i className="fas fa-rss rss fa-lg"></i>
                  </a>
                </div>
              </div>
            </p>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/donate">Donate</Link>
            <a target="_blank" rel="noopener noreferrer" href="/sitemap.xml">
              Sitemap (XML)
            </a>
          </div>
          &copy; {new Date().getFullYear()} Martijn Lindeman
        </span>
      </div>
    </footer>
  );
}

export default Footer;
