import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import HomepageLight from "./pages/HomepageLight";
import HomepageDark from "./pages/HomepageDark";
import Homepage2 from "./pages/Homepage2";
import Homepage2Light from "./pages/Homepage2Light";
import Homepage2Dark from "./pages/Homepage2Dark";
import Homepage3 from "./pages/Homepage3";
import Homepage3Light from "./pages/Homepage3Light";
import Homepage3Dark from "./pages/Homepage3Dark";
import Bloglist from "./pages/Bloglist";
import BlogDetails from "./pages/BlogDetails";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Donate from "./pages/Donate";
import Loader from "./components/elements/Loader";
import PageNotFound from "./pages/PageNotFound";
import Sitemap from "./pages/Sitemap";

function routes() {
  const reload = () => window.location.reload();
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={Homepage} />

          <Route path="/blogs" component={Bloglist} exact />
          <Route
            path="/blogs/blog-details/:id/:title"
            component={BlogDetails}
          />
          <Route path="/privacy-policy" component={PrivacyPolicy} exact />
          <Route path="/donate" component={Donate} exact />
          <Route path="/sitemap.xml" component={Sitemap} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default routes;
