import React, { useState } from "react";
import { Link } from "react-scroll";
import Logo from "../elements/Logo";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function Header3({
  light,
  logoSource,
  logoSourceDark,
  bootstrapNav,
  theme,
  handleToggleDarkmode,
}) {
  const [toggleNavbar, setToggleNavbar] = useState(false);

  const handleMobilenav = (e) => {
    e.preventDefault();
    setToggleNavbar(!toggleNavbar);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content-3")) {
      setToggleNavbar(false);
    }
  });

  return (
    <header
      className={
        theme === "dark"
          ? "desktop-header-3 fixed-top light"
          : "desktop-header-3 fixed-top"
      }>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <Logo
            logoSource={theme === "dark" ? logoSourceDark : logoSource}
            bootstrapNav={bootstrapNav}
          />
          <div className="d-lg-none">
            <FormControlLabel
              value="DarkMode"
              className="cust-switch "
              control={
                <Switch onClick={handleToggleDarkmode} color="default" />
              }
              label={theme === "light" ? "Light" : "Dark "}
            />
          </div>
          <button
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbarNavDropdown"
            data-toggle="collapse"
            type="button"
            onClick={handleMobilenav}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={
              toggleNavbar
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }
            id="navbarNavDropdown">
            <ul className="navbar-nav ml-auto scrollspy">
              <li className="nav-item">
                <Link
                  activeClass="active"
                  className="nav-link"
                  to="section-home"
                  spy={true}
                  smooth={true}
                  duration={500}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  className="nav-link"
                  to="section-about"
                  spy={true}
                  smooth={true}
                  duration={500}>
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  className="nav-link"
                  to="section-services"
                  spy={true}
                  smooth={true}
                  duration={500}>
                  Service
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  className="nav-link"
                  to="section-experiences"
                  spy={true}
                  smooth={true}
                  duration={500}>
                  Experience
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  className="nav-link"
                  to="section-works"
                  spy={true}
                  smooth={true}
                  duration={500}>
                  Works
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  className="nav-link"
                  to="section-blogs"
                  spy={true}
                  smooth={true}
                  duration={500}>
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  activeClass="active"
                  className="nav-link"
                  to="section-contact"
                  spy={true}
                  smooth={true}
                  duration={500}>
                  Contact
                </Link>
              </li>
              <li className="nav-item d-none d-lg-block">
                <FormControlLabel
                  value="DarkMode"
                  className={"cust-switch"}
                  control={
                    <Switch onClick={handleToggleDarkmode} color="default" />
                  }
                  label={theme === "light" ? "Light" : "Dark "}
                />
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header3;
