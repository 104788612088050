import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown/with-html";
import axios from "axios";
import Disqus from "disqus-react";
import Header3 from "../components/layouts/Header3";
import { Link } from "react-router-dom";
import Markdown from "markdown-to-jsx";

import BlogListSearch from "../components/elements/BlogListSearch";
import AutoComplete from "../components/elements/Autocomplete";
import HeaderOther from "../components/layouts/HeaderOther";

const allBlogs = [
  {
    id: 1,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Martijn Lindeman",
    category: "Reviews",
  },
  {
    id: 2,
    title: "Common Misconceptions About Payment",
    image: "images/blog/2.svg",
    filesource: "../../blogs/common-misconceptions-about-payment.md",
    date: "07 February, 2020",
    author: "Martijn Lindeman",
    category: "Tutorial",
  },
  {
    id: 3,
    title: "3 Things To Know About Startup Business",
    image: "images/blog/3.svg",
    filesource: "../../blogs/things-to-know-about-startup-business.md",
    date: "06 February, 2020",
    author: "Martijn Lindeman",
    category: "Business",
  },
  {
    id: 4,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Martijn Lindeman",
    category: "Reviews",
  },
  {
    id: 5,
    title: "Common Misconceptions About Payment",
    image: "images/blog/2.svg",
    filesource: "../../blogs/common-misconceptions-about-payment.md",
    date: "07 February, 2020",
    author: "Martijn Lindeman",
    category: "Tutorial",
  },
  {
    id: 6,
    title: "3 Things To Know About Startup Business",
    image: "images/blog/3.svg",
    filesource: "../../blogs/things-to-know-about-startup-business.md",
    date: "06 February, 2020",
    author: "Martijn Lindeman",
    category: "Business",
  },
  {
    id: 7,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Martijn Lindeman",
    category: "Reviews",
  },
  {
    id: 8,
    title: "Common Misconceptions About Payment",
    image: "images/blog/2.svg",
    filesource: "../../blogs/common-misconceptions-about-payment.md",
    date: "07 February, 2020",
    author: "Martijn Lindeman",
    category: "Tutorial",
  },
  {
    id: 9,
    title: "3 Things To Know About Startup Business",
    image: "images/blog/3.svg",
    filesource: "../../blogs/things-to-know-about-startup-business.md",
    date: "06 February, 2020",
    author: "Martijn Lindeman",
    category: "Business",
  },
  {
    id: 10,
    title: "5 Best App Development Tool for Your Project",
    image: "images/blog/1.svg",
    filesource: "../../blogs/best-app-development-tool.md",
    date: "09 February, 2020",
    author: "Martijn Lindeman",
    category: "Reviews",
  },
];

function BlogDetails(props) {
  const [content, setContent] = useState("");
  const blogId = props.match.params.id;
  const blogFile = props.match.params.title;
  const [toggleMenu, setToggleMenu] = useState(false);
  const [themeName, setThemeName] = useState("dark");

  themeName === "dark"
    ? document.body.classList.remove("dark")
    : document.body.classList.add("dark");
  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });
  const handleToggleDarkmode = (e) =>
    !e.target.checked ? setThemeName("dark") : setThemeName("light");

  useEffect(() => {
    axios.get(require(`../blogs/${blogFile}.md`)).then((result) => {
      setContent(result.data);
    });
  }, [content, blogFile]);
  console.log({ content });
  const disqusShortname = "martijn-lindeman-com"; //found in your Disqus.com dashboard
  const disqusConfig = {
    url: "https://martijn-lindeman.com", //Homepage link of this site.
    identifier: blogId,
    title: blogFile,
  };

  // document.body.classList.add("dark");
  //Uncomment the above line if you use dark version

  return (
    <>
      <HeaderOther
        logoSource="/images/martijnsvgdark.svg"
        logoSourceDark="/images/martijnsvglight.svg"
        toggleMenu={toggleMenu}
        handleToggleDarkmode={handleToggleDarkmode}
        headerToggler={headerToggler}
        theme={themeName}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <div className="spacer" data-height="96"></div>
        <div className="blog-page-section">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="blog-single shadow-dark p-20">
                  {/* <ReactMarkdown
                    source={content}
                    escapeHtml={false}></ReactMarkdown> */}

                  {/* <div className="mi-blog-details-comments mt-30">
                    <Disqus.DiscussionEmbed
                      shortname={disqusShortname}
                      config={disqusConfig}
                    />
                  </div> */}
                  <Markdown>{content}</Markdown>
                </div>
              </div>
              <div className="col-md-4">
                <div className="blog-single shadow-dark search">
                  <AutoComplete suggestions={allBlogs} />
                </div>
                <div className="blog-single shadow-dark">
                  <h4 style={{ margin: "10px 0px" }}>Recent Posts</h4>
                  <div className="recent-posts">
                    <ul>
                      {[...allBlogs.slice(0, 3)].map((el) => (
                        <Link to="#">
                          <li>{el.title}</li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default BlogDetails;
